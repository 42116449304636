import React from "react";
import { Container } from "react-grid-system";
import { useStaticQuery, graphql } from "gatsby";
import useLang from "Commons/Hooks/useLang";
import "./Focused.scss";

import Back1 from "../../images/focused/back1.svg";
import Back2 from "../../images/focused/back2.svg";

const Focused = () => {
  const data = useStaticQuery(graphql`
    query getFocusedText {
      allContentfulHomeTexts {
        nodes {
          vision
        }
      }
    }
  `);

  const [lang] = useLang();

  const { vision } = data.allContentfulHomeTexts.nodes[lang];

  return (
    <div className="container__focused">
      <Container>
        <div className="focused__text">
          <span>{vision}</span>
        </div>
      </Container>
      <Back1 className="fondo1" />
      <Back2 className="fondo2" />
    </div>
  );
};

export default Focused;
