import React, { useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import { useStaticQuery, graphql } from "gatsby";
import Button from "Commons/Button";
import useLang from "Commons/Hooks/useLang";
import "./OurServices.scss";

import ApplicationSecurity from "Images/ourServices/application-security.svg";
import PenetrationTesting from "Images/ourServices/penetration-testing.svg";
import MobileSecurity from "Images/ourServices/mobile-security.svg";
import Hardening from "Images/ourServices/hardening.svg";
import BugBounty from "Images/ourServices/bug-bounty.svg";
import SecurityAwareness from "Images/ourServices/security-awareness.svg";
import ClientSideAttacks from "Images/ourServices/client-side-attacks.svg";
import ThreatModeling from "Images/ourServices/threat-modeling.svg";

const OurServices = ({ texts }) => {
  const [servicesOpen, setServicesOpen] = useState(false);

  const servicesShown = [
    {
      title: texts.data[0].title,
      description: texts.data[0].description,
      icon: <ApplicationSecurity />,
    },
    {
      title: texts.data[1].title,
      description: texts.data[1].description,
      icon: <PenetrationTesting />,
    },
    {
      title: texts.data[2].title,
      description: texts.data[2].description,
      icon: <MobileSecurity />,
    },
    {
      title: texts.data[3].title,
      description: texts.data[3].description,
      icon: <Hardening />,
    },
  ];

  const servicesHidden = [
    {
      title: texts.data[4].title,
      description: texts.data[4].description,
      icon: <BugBounty />,
    },
    {
      title: texts.data[5].title,
      description: texts.data[5].description,
      icon: <SecurityAwareness />,
    },
    {
      title: texts.data[6].title,
      description: texts.data[6].description,
      icon: <ClientSideAttacks />,
    },
    {
      title: texts.data[7].title,
      description: texts.data[7].description,
      icon: <ThreatModeling />,
    },
  ];

  function mapeoServices(arr) {
    return arr.map(({ title, description, icon }, index) => (
      <Col key={index} sm={6} xs={6}>
        <div className="service__item">
          <div className="service__icon">{icon}</div>
          <div className="service__text">
            <span className="service__title">{title}</span>
            <span className="service__description">{description}</span>
          </div>
        </div>
      </Col>
    ));
  }

  const data = useStaticQuery(graphql`
    query getOurServicesText {
      allContentfulHomeTexts {
        nodes {
          servicesTitle
        }
      }
      allContentfulCommonTexts {
        nodes {
          seeMore
          seeLess
        }
      }
    }
  `);

  const [lang] = useLang();

  const { servicesTitle } = data.allContentfulHomeTexts.nodes[lang];
  const { seeMore, seeLess } = data.allContentfulCommonTexts.nodes[lang];

  return (
    <div className="container__services">
      <Container>
        <div className="services__container">
          <span className="ourServices__title">{servicesTitle}</span>
          <Row>{mapeoServices(servicesShown)}</Row>
          <div className={`${servicesOpen ? "showServices" : "hideServices"}`}>
            <Row>{mapeoServices(servicesHidden)}</Row>
          </div>
          <div className="services__button">
            <Button
              text={`${servicesOpen ? seeLess : seeMore}`}
              className="seeMore"
              onClick={() => setServicesOpen(!servicesOpen)}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default OurServices;
