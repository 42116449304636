import React from "react";
import { Container, Row, Col, Visible, Hidden } from "react-grid-system";
import Button from "Commons/Button";
import useLang from "Commons/Hooks/useLang";
import { useStaticQuery, graphql, Link } from "gatsby";
import "./Industries.scss";

const Industries = ({ texts }) => {
  const data = useStaticQuery(graphql`
    query getIndustriesTexts {
      allContentfulCommonTexts {
        nodes {
          seeMore
        }
      }
    }
  `);

  const [lang] = useLang();

  const { seeMore } = data.allContentfulCommonTexts.nodes[lang];

  return (
    <div className="container__industries">
      <Container>
        <Row>
          <Col md={7} xs={12}>
            <div>
              <div className="industries__title">
                <span>{texts.industriesTitle}</span>
              </div>
              <div className="industries__subtitle">
                <span>{texts.industriesSubtitle.industriesSubtitle}</span>
              </div>
            </div>
            <Hidden xs>
              <Link to="/industries">
                <Button text={seeMore} className="whiteButton" />
              </Link>
            </Hidden>
          </Col>
          <Col md={5} xs={12}>
            <div className="industries__list">
              <ul className="industries__ul">
                {texts.industriesList.map((text, i) => (
                  <li className="industries__li" key={i}>
                    <span className="tilde">✔ </span> {text}
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          <Visible xs>
            <Col xs={12}>
              <Link to="/industries">
                <Button text={seeMore} className="whiteButton" />
              </Link>
            </Col>
          </Visible>
        </Row>
      </Container>
    </div>
  );
};

export default Industries;
