import React from "react";
import { Container } from "react-grid-system";
import TrustedBy from "Commons/TrustedBy";
import Arrow from "Images/hero-downwards-arrow.svg";
import HomeBackground from "Images/home_background.mp4";
import "./Principal.scss";

const Principal = ({ texts }) => {
  return (
    <>
      <section className="hero__wrapper">
        <video preload="none" autoPlay muted loop className="hero-video">
          <source src={HomeBackground} type="video/mp4" />
        </video>
        <div className="container__principal">
          <Container>
            <div className="texts">
              <div className="title">{texts.title}</div>
              <div className="subtitle">{texts.subtitle.subtitle}</div>
              <Arrow className="arrow" />
            </div>
          </Container>
        </div>
      </section>
      <TrustedBy />
    </>
  );
};

export default Principal;
