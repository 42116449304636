import React from "react";
import { graphql } from "gatsby";
import { Container, Row } from "react-grid-system";
import "../base.scss";
import Header from "../components/commons/Header";
import Footer from "../components/commons/Footer";
import Principal from "../components/Home/Principal";
import OurServices from "../components/Home/OurServices";
import Industries from "../components/Home/Industries";
import Focused from "../components/Home/Focused";
import TrainingsList from "Components/Trainings/TrainingsList";
import { Helmet } from "react-helmet";
import useLang from "Commons/Hooks/useLang";

const IndexPage = ({ data }) => {
  const [lang] = useLang();
  const indexTexts = data.allContentfulHomeTexts.nodes[lang];
  const trainingTexts = data.allContentfulTrainingTexts.nodes[lang];

  return (
    <>
      <Helmet>
        <title>{indexTexts.pageTitle}</title>
      </Helmet>
      <Header />
      <Principal texts={indexTexts} />
      <OurServices texts={indexTexts.servicesList} />
      <Industries texts={indexTexts} />
      <Focused />
      {/* <div className="container__training"> */}
      <Container>
        <Row>
          <div className="training__title">
            <span>{trainingTexts.trainingTitle}</span>
          </div>
        </Row>
      </Container>
      {/* </div> */}
      <TrainingsList />
      {/* we are focused <br /> */}
      <Footer />
    </>
  );
};

export const query = graphql`
  query getHomePageTexts {
    allContentfulHomeTexts {
      nodes {
        pageTitle
        title
        subtitle {
          subtitle
        }
        servicesList {
          data {
            description
            title
          }
        }
        industriesList
        industriesTitle
        industriesSubtitle {
          industriesSubtitle
        }
      }
    }
    allContentfulTrainingTexts {
      nodes {
        trainingTitle
      }
    }
  }
`;

export default IndexPage;
